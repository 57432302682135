dialog[open] {
    border: none;
    padding: 50px 80px;
    max-width: 60%;
}

dialog[open] a:focus-visible {
  outline: none;
}

dialog[open]:focus-visible {
  outline: none;
}

@media (hover: hover) {
  .modal-exit:hover {
      color: #23c433;
  }
}

@media (max-width: 1024px) {
  dialog[open] {
    margin: auto;
    padding: 20px 50px;
    font-size: 15px;
    max-width: 90%;
  }

  dialog[open] button {
    padding: 10px 30px;
    font-size: 15px;
  }
}