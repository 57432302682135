.home__main-app {
    background-color: #100c34;
    color: #fff;
    width: 100vw;
    height: 100vh;
    padding: 50px;
}

.home__modal-spreads {
    display: flex;
    flex-direction: column;
}

.home__modal-links {
    color: #000;
    text-decoration: none;
    margin: 5px 0;
}

.home__modal-links:hover {
    color: #23c433;
}

.home__modal-title {
    margin-top: 0;
    margin-bottom: 20px;
}

.modal-exit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    cursor: pointer;
}

.home__margin-20 {
    margin: 20px 0;
}

.home__play-button {
    width: min-content;
}

@media (max-width: 1024px) {
    .home__main-app {
        height: 100%;
    }

    .home__play-button {
        width: 100%;
    }
}