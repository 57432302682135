.reveal {
    background-color: #100c34;
    width: 100vw;
    height: 100vh;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reveal__spreads {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    width: 90%;
    height: 100vh;
}

.reveal_modal-container {
    position: relative;
}

.reveal-image {
    height: 160px;
    width: auto;
    z-index: 0;
    cursor: pointer;
}

.reveal__modal-description {
    margin: 20px 0; 
}

.reveal__return-home {
    position: absolute;
    top: 50%;
    left: 5%;
}

.reveal__return-home--draw-one {
    padding-top: 50px;
}

.decoration-none {
    text-decoration: none;
}

@media (hover: hover) {
    .reveal-image:hover {
        z-index: 100;
    }
}

.reversed {
    transform: scaleY(-1) scaleX(-1);
}

.three-cards__container {
    display: flex;
    text-align: center;
    flex-direction: column;
}

.three-cards__selected-cards {
    display: flex;
    justify-content: center;
    align-items: center;
}

.three-cards {
    margin: 20px 30px;
    height: 240px;
    width: auto;
}

.situation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.situation.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.cross-purpose {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(290deg);
}

.cross-purpose.reversed {
    transform: translate(-50%, -50%) rotate(290deg) scaleY(-1) scaleX(-1);
}

.foundation {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.foundation.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.past-influences {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.past-influences.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.future {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.future.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.past {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.past.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.self {
    position: absolute;
    top: 88%;
    left: 70%;
    transform: translate(-50%, -50%);
}

.self.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.environment {
    position: absolute;
    top: 63%;
    left: 70%;
    transform: translate(-50%, -50%);
}

.environment.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.hopes-fears {
    position: absolute;
    top: 38%;
    left: 70%;
    transform: translate(-50%, -50%);
}

.hopes-fears.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.outcome {
    position: absolute;
    top: 13%;
    left: 70%;
    transform: translate(-50%, -50%);
}

.outcome.reversed {
    transform: translate(-50%, -50%) scaleY(-1) scaleX(-1);
}

.celtic-cross__title {
    position: absolute;
    top: 40%;
    left: 5%;
}

.summary__modal-exit {
    cursor: pointer;
    position: absolute;
    right: 0;
}

@media (hover: hover) {
    .summary__modal-exit:hover {
        color: #23c433;
    }
}

.reveal__modal-title {
    margin: 20px 0;
}

.reveal_modal-container .reveal-image {
    height: 256px;
    width: auto;
}

.reveal__title {
    margin-top: 100px;
    margin-bottom: 50px;
}

.three-cards__card-container {
    max-width: 250px;
}

.reveal__help-icon {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 10%;
    right: 10%
}

.reveal__summary-container {
    padding: 20px 0;
}

.reveal__btn-container {
    display: flex;
    column-gap: 20px;
    margin-top: 50px;
}

.reveal__btn-container--celtic-cross {
    position: absolute;
    top: 55%;
    left: 17%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.draw-one__container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.draw-one__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 1024px) {
    .reveal {
        background-color: #100c34;
        width: 100vw;
        height: 100%;
        max-height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        color: #fff;
    }

    .reveal__spreads {
        height: 100%;
        padding-bottom: 200px;
    }

    .reveal__btn-container {
        flex-direction: column;
        row-gap: 20px;
    }

    dialog[open] {
        padding: 20px;
    }

    .reveal__btn-container--celtic-cross {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .celtic-cross {
        height: 100vh;
        max-height: 100%;
    }

    .celtic-cross__title {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }

    .celtic-cross__container {
        font-size: 10px;
    }

    .celtic-cross__container p {
        font-size: 10px;
    }

    .reveal-image {
        height: 240px;
        width: auto;
    }

    .reveal_modal-container .reveal-image {
        height: 144px;
        width: auto;
    }
    
    .three-cards__selected-cards {
        flex-direction: column;
    }

    .three-cards__card-container {
        max-width: 90%;
    }

    .three-cards__present {
        order: -1;
    }


    .celtic-cross__card {
        height: 96px;
        width: 64px;
    }

    .situation {
        position: absolute;
        top: 40%;
        left: 40%;
        transform: translate(-50%, -50%);
    }
    
    .cross-purpose {
        position: absolute;
        top: 40%;
        left: 40%;
        transform: translate(-50%, -50%) rotate(290deg);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .foundation {
        position: absolute;
        top: 25%;
        left: 40%;
        transform: translate(-50%, -50%);
    }
    
    .past-influences {
        position: absolute;
        top: 55%;
        left: 40%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .future {
        position: absolute;
        top: 40%;
        left: 64%;
        transform: translate(-50%, -50%);
    }
    
    .past {
        position: absolute;
        top: 40%;
        left: 12%;
        transform: translate(-50%, -50%);
    }
    
    .self {
        position: absolute;
        top: 70%;
        left: 85%;
        transform: translate(-50%, -50%);
    }
    
    .environment {
        position: absolute;
        top: 55%;
        left: 85%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
    }
    
    .hopes-fears {
        position: absolute;
        top: 40%;
        left: 85%;
        transform: translate(-50%, -50%);
    }
    
    .outcome {
        position: absolute;
        top: 25%;
        left: 85%;
        transform: translate(-50%, -50%);
    }

    .reveal__return-home {
        position: absolute;
        top: 85%;
        left: 50%;
        transform: translate(-50%);
    }

    .reveal__help-icon {
        top: 150px;
        left: 85%;
    }
  }

