.btn {
    padding: 15px 80px;
    background-color: #23c433;
    color: #fff;
    border: none;
    font-size: 20px;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1024px) {
    .btn {
        width: 100%;
        padding: 15px 20px;
    }
}

.btn:hover {
    transform: scale(1.05);
    cursor: pointer;
}
