.meanings {
    background-color: #100c34;
    color: #fff;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    padding: 50px;
}

.meanings__title {
    margin-bottom: 20px;
}

.meanings__text {
    margin-bottom: 50px;
}

.meanings__descriptions {
    margin-bottom: 10px;
}

.meanings__list {
    list-style: none;
    margin: 5px 0;
}

.meanings__links {
    color: #fff;
}

.meanings__links:hover {
    color: #23c433;
}

.meanings__back-top {
    cursor: pointer;
    text-decoration: underline;
}

.meanings__back-top:hover {
    color: #23c433;
}